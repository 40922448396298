<template>
  <div class="app-container">
    <el-card>
      <div class="top-btn mgt16">
        <el-button type="primary" @click="addInfo()" plain>新增</el-button>
      </div>
      <el-table
        class="mgt16"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
      >
        <el-table-column align="center" label="序号" width="60px">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column align="left" label="项目">
          <template slot-scope="scope">{{ scope.row.project }}</template>
        </el-table-column>
        <el-table-column align="left" prop="created_at" label="时间">
          <template slot-scope="scope">{{ scope.row.starttime }}-{{ scope.row.endtime }}</template>
        </el-table-column>
        <el-table-column  property="address" label="操作" align="center" width="150px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleView(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      
      <el-dialog
        v-if="dialogFormVisible"
        :title="textMap[dialogStatus]"
        :visible.sync="dialogFormVisible"
        :before-close="handleClose"
        width="30%"
        minWidth="600px">
        <addClassSection
          ref="dialogForm"
          @changeDialogFormVisible="changeDialogFormVisible($event)"
          @changeShow="changeShow"
          :dialog-status="dialogStatus"
          :edit-data="form"/>
      </el-dialog>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </el-card>
  </div>
</template>

<script>
import * as api from "@/api/users";
import addClassSection from "@/views/add/addclassSectionSetting.vue";
export default {
  components: {
    addClassSection,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      form: {},
      infoForm: {},
      idis: "",
      formLabelWidth: "120px",
      Authorization: "",
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "编辑",
        create: "新增",
        view: "详情",
      },
      currentPage4: 4,
      listQuest: {
        page: 1,
        pageSize: 10,
      },
      roleList: [],
      systemList: [],
      total: 0,
      options: [
        {
          label: "有",
          value: 1,
        },
        {
          label: "没有",
          value: 0,
        },
      ],
      roles: [],
      tableData: [],
      input: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.d1();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.d1();
    },
    addInfo() {
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
    },
    handleDelete(value) {
      console.log(value);
      api.handdelete(value).then((res) => {
        if (res.status == 1) {
          this.$confirm("确认删除")
            .then(() => {
              this.$message.success(res.msg);
              this.d1();
            })
            .catch(() => {});
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 编辑
    handleView(row) {
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.form = row;
      console.log(this.form);
    },
    indexMethod(index) {
      return index * 1;
    },
    d1() {
      api
        .getSysslassdayList({
          page: this.page,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.status == 1) {
            console.log(res.data.records.length, "hahahaha");
            this.tableData = res.data.records;
            this.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeShow(data) {
      this.dialogFormVisible = data;
    },
    changeDialogFormVisible(data) {
      this.dialogFormVisible = data;
      this.d1();
      this.$message({
        message: "操作成功",
        type: "success",
      });
    },
    select(e) {
      console.log(e);
    },
    getType() {
      api.getCurrUserItem().then((res) => {
        this.idis = res.data.rname;
      });
    },
    loadData() {
      this.d1();
      this.getType();
      // api
      //   .getRoleList({
      //     page: 1,
      //     pageSize: 10,
      //     classnum: ""
      //   })
      //   .then(res => {
      //     this.roleList = res.data.records;
      //   });
      this.roleList = JSON.parse(sessionStorage.getItem("menuList"));
    },
    handleClose(done) {
      done();
    },
    handleEdit(row) {
      this.getRole();
      console.log(row);
      this.form.id = row.id;
      this.roles.roleName = row.role_name;
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    goSearch(form) {
      console.log(form, "赶紧的");
      form.page = 1;
      form.pageSize = 1000;
      if (!form.classnum) {
        form.classnum = "";
      }
      if (!form.roleid) {
        form.roleid = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-container {
  width: 100%;
  height: 100% !important;
}

.info {
  display: flex;
  span {
    line-height: 41px;
  }
}
/deep/.el-dialog__title {
  display: flex;
  flex-direction: row;
}
/deep/.el-dialog__body {
  padding: 0px;
}
.gutter {
  width: 200px;
}
</style>
