<template>
  <div class="mgl10 mgr10">
    <el-row :gutter="1">
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }">
        <hr style="border:0.5px solid blue" />
        <el-form ref="form" :model="editform" :rules="rules" label-position="center" label-width="100px">
          <div>
            <el-form-item label="项目名称:" prop="project">
              <el-input v-model="editData.project"></el-input>
            </el-form-item>
          </div>
          <div class="">
            <el-form-item label="开始时间:" prop="starttime" class="item1">
              <el-time-select
                v-model="editData.starttime"
                :picker-options="{
                  start: '00:00',
                  step: '00:05',
                  end: '24:00'
                }"
                placeholder="选择时间">
              </el-time-select>
            </el-form-item>
            <el-form-item  label="结束时间:" prop="endtime">
               <el-time-select
                v-model="editData.endtime"
                :picker-options="{
                  start: editData.starttime,
                  step: '00:05',
                  end: '24:00'
                }"
                placeholder="选择时间">
              </el-time-select>
            </el-form-item>
          </div>
        </el-form>
        <div class="dialog-footer" style="text-align: center">
          <el-button @click="cancel()"> 取消 </el-button>
          <el-button
            type="primary"
            @click=" dialogStatus == 'create' ? createData(editform) : updateData(editform) "
          >
            确认
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as api from "@/api/users";
import { getHttpUrl } from "@/utils/request";
export default {
  components: { },
  props: {
    editData: {
      type: Object,
      default: () => {}
    },
    dialogStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    var validateProject = (rule, value, callback) => {
      alert(value);
      if (value == "") {
        callback(new Error("请输入项目"));
      } else {
        callback();
      }
    };
    var validateStartTime = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入开始时间"));
      } else {
        callback();
      }
    };
    var validateEndTime = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入结束时间"));
      } else {
        callback();
      }
    };

    return {
      imgBaseUrl: getHttpUrl(),
      id:'',
      editform:{},
      starttime: "",
      endtime: "",
      options: [],
      value: [],
      returnImgUrl: "",
      props: ["inputName"],
      readonly: true,
      typeList: [],
      form: {},
      idis: "",
      value1: "",
      systemList: [],
      cnameList: [],
      unitList: [],
      groupList: [],
      sexList: [
        {
          id: 0,
          label: "女"
        },
        {
          id: 1,
          label: "男"
        }
      ],
      roleList: [],

      infoFlag: true,
      userTypeList: [],
      
      // 表单校验规则
      rules: {
        // 没有进行任何输入时，不会触发change，但一定会触发blur事件
        project: [{ required: validateProject, trigger: "blur" }],
        starttime: [{ required: validateStartTime, trigger: "blur" }],
        endtime: [{ required: validateEndTime, trigger: "blur" }]
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.load();
    this.d1()
  },
  mounted() {},
  methods: {
    d1() {
      api.getSysslassdayList({
          page: 1,
          pageSize: 1000
        })
        .then(res => {
          let rows = res.data.records
          if (res.status == 1) {
            let map = rows.map(item => {
              return {starttime:item.starttime,endtime:item.endtime,project:item.project,id:item.id}
            })
            this.options =map;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    t1() {
    },
    elInFile(f) {
      let formdata = new FormData();
      formdata.append("file", f.raw);

      api.transPhoto(formdata).then(res => {
        this.returnImgUrl = res.data;
        this.$message({
          showClose: true,
          message: "头像上传成功",
          type: "success"
        });
      });
      
    },
    load() {
      api.getCurrUserItem().then(res => {
        this.idis = res.data.rname;
      });
      // api
      //   .getRoleList({
      //     page: 1,
      //     pageSize: 10,
      //     classnum: ""
      //   })
      //   .then(res => {
      //     this.roleList = res.data.records;
      //   });
        this.roleList = JSON.parse(sessionStorage.getItem('menuList'));
      api
        .getSystemList({
          page: 1,
          pageSize: 1000,
          classnum: "",
          roleid: ""
        })
        .then(res => {
          this.systemList = res.data.records;
        });
    },
    
    cancel() {
      this.$emit("changeShow", false);
    },
    getTrue() {
      return true;
    },
    getFalse() {
      return false;
    },
    // 新增
    createData(form) {
        api.addSysslassdayList(form).then(res=>{
          if(res.status == 1){
            this.$emit("changeDialogFormVisible", false);
          }  
        })  
    },
    // 编辑提交
    updateData() {
      api.updateSysslassday(this.editData).then(res=>{
        if(res.status == 1){
          this.$nextTick(()=>{
            this.$emit("changeDialogFormVisible", false);
          })
        }
      })
    },
    addNew(temp) {
      this.form = temp;
    },
    showData(record) {
      this.id = record;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form {margin-top: 20px;}	
.dialog-footer {margin-bottom: 20px;}
</style>
