var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mgl10 mgr10"},[_c('el-row',{attrs:{"gutter":1}},[_c('el-col',{attrs:{"xs":{ span: 24 },"sm":{ span: 24 },"md":{ span: 24 }}},[_c('hr',{staticStyle:{"border":"0.5px solid blue"}}),_c('el-form',{ref:"form",attrs:{"model":_vm.editform,"rules":_vm.rules,"label-position":"center","label-width":"100px"}},[_c('div',[_c('el-form-item',{attrs:{"label":"项目名称:","prop":"project"}},[_c('el-input',{model:{value:(_vm.editData.project),callback:function ($$v) {_vm.$set(_vm.editData, "project", $$v)},expression:"editData.project"}})],1)],1),_c('div',{},[_c('el-form-item',{staticClass:"item1",attrs:{"label":"开始时间:","prop":"starttime"}},[_c('el-time-select',{attrs:{"picker-options":{
                start: '00:00',
                step: '00:05',
                end: '24:00'
              },"placeholder":"选择时间"},model:{value:(_vm.editData.starttime),callback:function ($$v) {_vm.$set(_vm.editData, "starttime", $$v)},expression:"editData.starttime"}})],1),_c('el-form-item',{attrs:{"label":"结束时间:","prop":"endtime"}},[_c('el-time-select',{attrs:{"picker-options":{
                start: _vm.editData.starttime,
                step: '00:05',
                end: '24:00'
              },"placeholder":"选择时间"},model:{value:(_vm.editData.endtime),callback:function ($$v) {_vm.$set(_vm.editData, "endtime", $$v)},expression:"editData.endtime"}})],1)],1)]),_c('div',{staticClass:"dialog-footer",staticStyle:{"text-align":"center"}},[_c('el-button',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogStatus == 'create' ? _vm.createData(_vm.editform) : _vm.updateData(_vm.editform)}}},[_vm._v(" 确认 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }